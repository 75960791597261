<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Data Eselon</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="9" lg="9" md="9" sm="12" xs="12" class="px-0">
              <v-btn
                text
                class="text-capitalize caption headline-color"
                color="primary"
                @click="
                  visible = true;
                  isEdit = false;
                "
                >Buat Eselon<v-icon class="ml-2" color="primary"
                  >add_circle</v-icon
                ></v-btn
              >
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" class="px-0">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="eselons"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.tanggal_libur`]="{ item }">
              <span>{{ item.tanggal_libur | date }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon small color="primary" @click="handleEdit(item)"
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn icon small color="primary" @click="handleDelete(item)"
                ><v-icon small>delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Eselon
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kode</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.eselon_id"
                :disabled="isEdit"
                outlined
                dense
                :rules="[
                  v => !!v || 'Kode harus diisi',
                  v => v.length <= 2 || 'Kode tidak boleh lebih dari 2 karakter'
                ]"
                hint="Contoh: 1, 1A, 1B, 2, ..."
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Eselon</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.eselon"
                outlined
                dense
                :rules="[v => !!v || 'Eselon harus diisi']"
                hint="Contoh: I, Ia, Ib, II, ..."
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tingkatan/Level</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                v-model="form.tingkatan"
                :items="levels"
                outlined
                dense
                :rules="[v => !!v || 'Tingkatan harus diisi']"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Keterangan</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.keterangan"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Golongan Awal</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-autocomplete
                v-model="form.gol_awal"
                :loading="loadingGolongan"
                :items="gols"
                outlined
                dense
                item-text="gol"
                item-value="gol"
                :rules="[v => !!v || 'Golongan awal harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Golongan Akhir</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-autocomplete
                v-model="form.gol_akhir"
                :loading="loadingGolongan"
                :items="gols"
                outlined
                dense
                item-text="gol"
                item-value="gol"
                :rules="[v => !!v || 'Golongan akhir harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      isEdit: false,
      loading: false,
      loadingGolongan: false,
      formLoading: false,
      gols: [],
      levels: [1, 2, 3, 4, 5],
      search: null,
      headers: [
        {
          text: "Kode",
          value: "eselon_id",
          sortable: false,
          align: "center"
        },
        {
          text: "Eselon",
          value: "eselon",
          sortable: false,
          align: "center"
        },
        {
          text: "Tingkatan",
          value: "tingkatan",
          sortable: false,
          align: "center"
        },
        {
          text: "Keterangan",
          value: "keterangan",
          sortable: false,
          align: "center"
        },
        {
          text: "Golongan Awal",
          value: "gol_awal",
          sortable: false,
          align: "center"
        },
        {
          text: "Golongan Akhir",
          value: "gol_akhir",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      eselons: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["eselon_id"],
        sortDesc: [false]
      },
      dayDiffTry: 0,
      dayDiff: 0,
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        eselon_id: null,
        eselon: null,
        tingkatan: null,
        keterangan: null,
        gol_awal: null,
        gol_akhir: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getEselonList);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getEselonList);
    },
    visible(val) {
      if (!val) {
        this.dayDiff = 0;
        this.dayDiffTry = 0;
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [this.search, this.year, this.unit_kerja_2_id].join();
    }
  },
  methods: {
    handleEdit(item) {
      this.visible = true;
      this.isEdit = true;
      this.getDetailEselon(item.eselon_id);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              eselon_id: item.eselon_id
            };
            this.deleteEselon(payload);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("action", this.isEdit ? 2 : 1);
        formData.append("eselon_id", this.form.eselon_id);
        formData.append("eselon", this.form.eselon);
        formData.append("tingkatan", this.form.tingkatan);
        formData.append("keterangan", this.form.keterangan);
        formData.append("gol_awal", this.form.gol_awal);
        formData.append("gol_akhir", this.form.gol_akhir);
        this.saveEselon(formData);
      }
    },
    // Service
    async getGolonganListAll() {
      try {
        this.loadingGolongan = true;
        await DataUtamaService.getGolonganListAll()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.gols = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingGolongan = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getEselonList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await DataUtamaService.getEselonList({
          filter: {
            search: this.search
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let eselons = list;
              eselons.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.eselons = eselons;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveEselon(data) {
      try {
        this.formLoading = true;
        await DataUtamaService.saveEselon(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getEselonList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailEselon(id) {
      try {
        this.loading = true;
        await DataUtamaService.getEselonDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              (this.form.eselon_id = data.eselon_id),
                (this.form.eselon = data.eselon),
                (this.form.tingkatan = data.tingkatan),
                (this.form.keterangan = data.keterangan),
                (this.form.gol_awal = data.gol_awal),
                (this.form.gol_akhir = data.gol_akhir);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteEselon(payload) {
      try {
        this.formLoading = true;
        await DataUtamaService.deleteEselon(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getEselonList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getGolonganListAll();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
